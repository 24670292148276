// gc-owners: asap-payments, turbo-growth
// DO NOT EDIT!! This file was automatically generated by ./scripts/generate-tracking-plan.js
export enum TrackingEvent {
  ACTIVATE_PAYOUTS_DIALOG_VIEWED = "Activate Payouts Dialog Viewed",
  ADMIN_DASHBOARD_DOM_ELEMENT_EVENT = "Admin Dashboard - DOM Element Event",
  BANK_ACCOUNT_DATA_BOOK_A_FREE_CONSULTATION_CLICKED = "Bank Account Data - Book A Free Consultation Clicked",
  BANK_ACCOUNT_DATA_CONTACT_SALES_CLICKED = "Bank Account Data - Contact Sales Clicked",
  BANK_ACCOUNT_DATA_GO_TO_BANK_ACCOUNT_DATA_CLICKED = "Bank Account Data - Go To Bank Account Data Clicked",
  BANK_ACCOUNT_DATA_GO_TO_VALUE_PROP_CLICKED = "Bank Account Data - Go To Value Prop Clicked",
  BANK_ACCOUNT_DATA_LEARN_MORE_CLICKED = "Bank Account Data - Learn More Clicked",
  BANK_ACCOUNT_DATA_LOG_IN_CLICKED = "Bank Account Data - Log In Clicked",
  BANK_ACCOUNT_DATA_PAGE_VIEWED = "Bank Account Data - Page Viewed",
  BANK_ACCOUNT_DATA_SHARE_LINK_CLICKED = "Bank Account Data - Share link Clicked",
  BANNER_DISMISSED = "Banner - Dismissed",
  BANNER_PRIMARY_CTA_CLICKED = "Banner - Primary CTA Clicked",
  BANNER_SECONDARY_CTA_CLICKED = "Banner - Secondary CTA Clicked",
  BANNER_VIEWED = "Banner - Viewed",
  CUSTOMERS_ADD_CUSTOMER_CTA_CLICKED = "Customers - Add Customer CTA Clicked",
  CUSTOMERS_ADD_TO_PLAN_COMPLETED = "Customers - Add to Plan Completed",
  CUSTOMERS_ADD_TO_PLAN_CTA_CLICKED = "Customers - Add to Plan CTA Clicked",
  CUSTOMERS_ADD_TO_PLAN_PAGE_VIEWED = "Customers - Add to Plan Page Viewed",
  CUSTOMERS_BANK_ACCOUNTS_PAGE_VIEWED = "Customers - Bank Accounts Page Viewed",
  CUSTOMERS_CONTINUE_CTA_CLICKED = "Customers - Continue CTA Clicked",
  CUSTOMERS_CREATE_INSTALMENT_SCHEDULE_CTA_CLICKED = "Customers - Create Instalment Schedule CTA Clicked",
  CUSTOMERS_CREATE_INSTANT_PAYMENT_CTA_CLICKED = "Customers - Create Instant Payment CTA Clicked",
  CUSTOMERS_CREATE_PAYMENT_CTA_CLICKED = "Customers - Create Payment CTA Clicked",
  CUSTOMERS_CREATE_SUBSCRIPTION_CTA_CLICKED = "Customers - Create Subscription CTA Clicked",
  CUSTOMERS_CUSTOMER_INVITE_FORM_SUBMITTED = "Customers - Customer Invite Form Submitted",
  CUSTOMERS_DETAIL_PAGE_CTA_CLICKED = "Customers - Detail Page CTA Clicked",
  CUSTOMERS_DETAIL_PAGE_VIEWED = "Customers - Detail Page Viewed",
  CUSTOMERS_INSTALMENT_SCHEDULE_CREATED = "Customers - Instalment Schedule Created",
  CUSTOMERS_INSTALMENT_SCHEDULE_PAGE_VIEWED = "Customers - Instalment Schedule Page Viewed",
  CUSTOMERS_INSTANT_PAYMENT_CREATED = "Customers - Instant Payment Created",
  CUSTOMERS_INSTANT_PAYMENT_CREATE_PAGE_VIEWED = "Customers - Instant Payment Create Page Viewed",
  CUSTOMERS_INSTANT_PAYMENT_SHARED = "Customers - Instant Payment Shared",
  CUSTOMERS_INSTANT_PAYMENT_SHARED_DEDUPED = "Customers - Instant Payment Shared Deduped",
  CUSTOMERS_INSTANT_PAYMENT_SHARE_PAGE_VIEWED = "Customers - Instant Payment Share Page Viewed",
  CUSTOMERS_INVITE_CUSTOMER_FORM_SWITCH_TOGGLED = "Customers - Invite Customer Form Switch Toggled",
  CUSTOMERS_LEARN_MORE_CTA_CLICKED = "Customers - Learn More CTA Clicked",
  CUSTOMERS_LIST_PAGE_VIEWED = "Customers - List Page Viewed",
  CUSTOMERS_MANDATES_PAGE_VIEWED = "Customers - Mandates Page Viewed",
  CUSTOMERS_OPEN_LIST_CTA_CLICKED = "Customers - Open List CTA Clicked",
  CUSTOMERS_PAYER_BENEFITS_DOWNLOADED = "Customers - Payer Benefits Downloaded",
  CUSTOMERS_PAYMENT_CREATED = "Customers - Payment Created",
  CUSTOMERS_PAYMENT_CREATE_PAGE_VIEWED = "Customers - Payment Create Page Viewed",
  CUSTOMERS_PAYMENT_TIMINGS_TIMELINE_VIEWED = "Customers - Payment Timings Timeline Viewed",
  CUSTOMERS_QRCODE_CUSTOMISED = "Customers - QRCode Customised",
  CUSTOMERS_REMINDER_EMAIL_CTA_CLICKED = "Customers - Reminder Email CTA Clicked",
  CUSTOMERS_SHARE_COMPLETED = "Customers - Share Completed",
  CUSTOMERS_SHARE_COMPLETED_DEDUPED = "Customers - Share Completed Deduped",
  CUSTOMERS_SHARE_PAGE_VIEWED = "Customers - Share Page Viewed",
  CUSTOMERS_SHARE_TAB_UPDATED = "Customers - Share Tab Updated",
  CUSTOMERS_SUBSCRIPTION_CREATED = "Customers - Subscription Created",
  CUSTOMERS_SUBSCRIPTION_CREATE_PAGE_VIEWED = "Customers - Subscription Create Page Viewed",
  CUSTOMERS_SUCCESS_PAGE_VIEWED = "Customers - Success Page Viewed",
  CUSTOMERS_TEMPLATE_EDITED = "Customers - Template Edited",
  CUSTOMERS_TEMPLATE_LIST_VIEWED = "Customers - Template List Viewed",
  CUSTOMERS_TEMPLATE_PREVIEW_EXPANDED = "Customers - Template Preview Expanded",
  CUSTOMERS_TEMPLATE_TYPE_UPDATED = "Customers - Template Type Updated",
  DASHBOARD_CREDITORS_PAGE_VIEWED = "Dashboard - Creditors Page Viewed",
  DASHBOARD_DEVELOPERS_PAGE_VIEWED = "Dashboard - Developers Page Viewed",
  DASHBOARD_EVENTS_PAGE_VIEWED = "Dashboard - Events Page Viewed",
  DASHBOARD_EXPORT_BUTTON_CLICKED = "Dashboard - Export Button Clicked",
  DASHBOARD_EXTERNAL_LINK_CLICKED = "Dashboard - External Link Clicked",
  DASHBOARD_REFER_AND_EARN_CTA_CLICKED = "Dashboard - Refer And Earn CTA Clicked",
  DASHBOARD_REFER_CTA_CLICKED = "Dashboard - Refer CTA Clicked",
  DASHBOARD_REFER_POPUP_DISMISSED = "Dashboard - Refer Popup Dismissed",
  DASHBOARD_REFER_POPUP_VIEWED = "Dashboard - Refer Popup Viewed",
  DASHBOARD_ROUTETO_REDIRECT = "Dashboard - RouteTo Redirect",
  DASHBOARD_SEARCH_PAGE_VIEWED = "Dashboard - Search Page Viewed",
  DASHBOARD_TERMS_CLICKED = "Dashboard - Terms Clicked",
  EXPERIMENTS_DECISION_CREATED = "Experiments - Decision Created",
  GET_STARTED_COLLECTIONS_ENABLED_DIALOG_CLOSED = "Get Started - Collections Enabled Dialog Closed",
  GET_STARTED_COLLECTIONS_ENABLED_DIALOG_OPENED = "Get Started - Collections Enabled Dialog Opened",
  GET_STARTED_MESSAGE_TEMPLATES_CTA_CLICKED = "Get Started - Message Templates CTA Clicked",
  GET_STARTED_PAYMENT_CREATION_SLIDESHOW_SLIDE_CHANGED = "Get Started - Payment Creation Slideshow Slide Changed",
  GET_STARTED_PAYMENT_CREATION_SUBMITTED = "Get Started - Payment Creation Submitted",
  GET_STARTED_PAYMENT_CREATION_TYPE_CLICKED = "Get Started - Payment Creation Type Clicked",
  GET_STARTED_PAYMENT_PREVIEW_DIALOG_VIEWED = "Get Started - Payment Preview Dialog Viewed",
  GET_STARTED_PAYMENT_SETTINGS_INTRO_PAGE_VIEWED = "Get Started - Payment Settings Intro Page Viewed",
  GET_STARTED_PAYMENT_SETTINGS_LOGO_SKIPPED = "Get Started - Payment Settings Logo Skipped",
  GET_STARTED_PAYMENT_SETTINGS_LOGO_SUBMITTED = "Get Started - Payment Settings Logo Submitted",
  GET_STARTED_PAYMENT_SETTINGS_PRODUCT_SUBMITTED = "Get Started - Payment Settings Product Submitted",
  GET_STARTED_PAYMENT_SHARE_EMAIL_INVITE_SUBMITTED = "Get Started - Payment Share Email Invite Submitted",
  GET_STARTED_PAYMENT_SHARE_INITIATED = "Get Started - Payment Share Initiated",
  GET_STARTED_PAYMENT_SHARE_MESSAGE_TEMPLATES_LINK_CLICKED = "Get Started - Payment Share Message Templates Link Clicked",
  GET_STARTED_PAYMENT_SHARE_PAGE_VIEWED = "Get Started - Payment Share Page Viewed",
  GET_STARTED_PAYMENT_TYPE_CLICKED = "Get Started - Payment Type Clicked",
  GET_STARTED_PAYMENT_TYPE_SKIPPED = "Get Started - Payment Type Skipped",
  GET_STARTED_PAYOUT_DIALOG_CTA_CLICKED = "Get Started - Payout Dialog CTA Clicked",
  GET_STARTED_PAYOUT_DIALOG_VIEWED = "Get Started - Payout Dialog Viewed",
  GET_STARTED_STEP_CARD_CTA_CLICKED = "Get Started - Step Card CTA Clicked",
  GET_STARTED_STEP_CARD_FOCUSED = "Get Started - Step Card Focused",
  IMPORTS_BULK_CHANGE_3_STEPS_NEXT_BUTTON_CLICKED = "Imports Bulk Change - 3 Steps Next Button Clicked",
  IMPORTS_BULK_CHANGE_ACH_OPTION_SELECTED = "Imports Bulk Change - ACH Option Selected",
  IMPORTS_BULK_CHANGE_ADD_EMAIL_CHECKBOX_CLICKED = "Imports Bulk Change - Add Email Checkbox Clicked",
  IMPORTS_BULK_CHANGE_AUDDIS_LIVE_TOOLTIP_CLICKED = "Imports Bulk Change - AUDDIS Live Tooltip Clicked",
  IMPORTS_BULK_CHANGE_AUDDIS_STATUS_TOOLTIP_CLICKED = "Imports Bulk Change - AUDDIS Status Tooltip Clicked",
  IMPORTS_BULK_CHANGE_BACK_TO_DASHBOARD_CTA_CLICKED = "Imports Bulk Change - Back To Dashboard CTA Clicked",
  IMPORTS_BULK_CHANGE_BACS_OPTION_SELECTED = "Imports Bulk Change - Bacs Option Selected",
  IMPORTS_BULK_CHANGE_BANK_ACCOUNT_METADATA_TOOLTIP_CLICKED = "Imports Bulk Change - Bank Account Metadata Tooltip Clicked",
  IMPORTS_BULK_CHANGE_BECS_NZ_OPTION_SELECTED = "Imports Bulk Change - BECS NZ Option Selected",
  IMPORTS_BULK_CHANGE_BECS_OPTION_SELECTED = "Imports Bulk Change - BECS Option Selected",
  IMPORTS_BULK_CHANGE_CANCEL_CTA_CLICKED = "Imports Bulk Change - Cancel CTA Clicked",
  IMPORTS_BULK_CHANGE_CHOOSE_FILE_CTA_CLICKED = "Imports Bulk Change - Choose File CTA Clicked",
  IMPORTS_BULK_CHANGE_CONTACT_SUPPORT_CTA_CLICKED = "Imports Bulk Change - Contact Support CTA Clicked",
  IMPORTS_BULK_CHANGE_CONTINUE_CTA_CLICKED = "Imports Bulk Change - Continue CTA Clicked",
  IMPORTS_BULK_CHANGE_CURRENT_SUN_TOOLTIP_CLICKED = "Imports Bulk Change - Current SUN Tooltip Clicked",
  IMPORTS_BULK_CHANGE_CUSTOMER_HUB_CTA_CLICKED = "Imports Bulk Change - Customer Hub CTA Clicked",
  IMPORTS_BULK_CHANGE_CUSTOMER_METADATA_TOOLTIP_CLICKED = "Imports Bulk Change - Customer Metadata Tooltip Clicked",
  IMPORTS_BULK_CHANGE_DOWNLOAD_CTA_CLICKED = "Imports Bulk Change - Download CTA Clicked",
  IMPORTS_BULK_CHANGE_DOWNLOAD_FILE_CTA_CLICKED = "Imports Bulk Change - Download File CTA Clicked",
  IMPORTS_BULK_CHANGE_DOWNLOAD_TEMPLATE_CTA_CLICKED = "Imports Bulk Change - Download Template CTA Clicked",
  IMPORTS_BULK_CHANGE_EMAIL_ADDRESS_TOOLTIP_CLICKED = "Imports Bulk Change - Email Address Tooltip Clicked",
  IMPORTS_BULK_CHANGE_EMAIL_REG_TOOLTIP_CLICKED = "Imports Bulk Change - Email Reg Tooltip Clicked",
  IMPORTS_BULK_CHANGE_ENABLE_2FA_CTA_CLICKED = "Imports Bulk Change - Enable 2FA CTA Clicked",
  IMPORTS_BULK_CHANGE_FINISH_AND_SUBMIT_CTA_CLICKED = "Imports Bulk Change - Finish And Submit CTA Clicked",
  IMPORTS_BULK_CHANGE_FIRST_NAME_TOOLTIP_CLICKED = "Imports Bulk Change - First Name Tooltip Clicked",
  IMPORTS_BULK_CHANGE_FM_OPTION_SELECTED = "Imports Bulk Change - FM Option Selected",
  IMPORTS_BULK_CHANGE_FULL_MIGRATION_OPTION_SELECTED = "Imports Bulk Change - Full Migration Option Selected",
  IMPORTS_BULK_CHANGE_FULL_SUN_NAME_TOOLTIP_CLICKED = "Imports Bulk Change - Full SUN Name Tooltip Clicked",
  IMPORTS_BULK_CHANGE_GET_STARTED_CTA_CLICKED = "Imports Bulk Change - Get Started CTA Clicked",
  IMPORTS_BULK_CHANGE_HAVING_TROUBLE_CTA_CLICKED = "Imports Bulk Change - Having Trouble CTA Clicked",
  IMPORTS_BULK_CHANGE_HOW_DO_I_EDIT_CSV_FILE_LINK_CLICKED = "Imports Bulk Change - How Do I Edit CSV File Link Clicked",
  IMPORTS_BULK_CHANGE_LEADING_ZEROS_TOOLTIP_CLICKED = "Imports Bulk Change - Leading Zeros Tooltip Clicked",
  IMPORTS_BULK_CHANGE_LEARN_MORE_CTA_CLICKED = "Imports Bulk Change - Learn More CTA Clicked",
  IMPORTS_BULK_CHANGE_LEARN_MORE_CUSTOM_METADATA_LINK_CLICKED = "Imports Bulk Change - Learn More Custom Metadata Link Clicked",
  IMPORTS_BULK_CHANGE_LONG_SUN_NAME_TOOLTIP_CLICKED = "Imports Bulk Change - Long SUN Name Tooltip Clicked",
  IMPORTS_BULK_CHANGE_MANDATE_METADATA_TOOLTIP_CLICKED = "Imports Bulk Change - Mandate Metadata Tooltip Clicked",
  IMPORTS_BULK_CHANGE_MANDATE_REFERENCE_TOOLTIP_CLICKED = "Imports Bulk Change - Mandate Reference Tooltip Clicked",
  IMPORTS_BULK_CHANGE_MA_OPTION_SELECTED = "Imports Bulk Change - MA Option Selected",
  IMPORTS_BULK_CHANGE_NAME_OF_BANK_TOOLTIP_CLICKED = "Imports Bulk Change - Name Of Bank Tooltip Clicked",
  IMPORTS_BULK_CHANGE_NEED_HELP_REQUESTING_DATA_FROM_PROVIDER_LINK_CLICKED = "Imports Bulk Change - Need Help Requesting Data From Provider Link Clicked",
  IMPORTS_BULK_CHANGE_NEXT_STEP_CTA_CLICKED = "Imports Bulk Change - Next Step CTA Clicked",
  IMPORTS_BULK_CHANGE_NOTIFY_CUSTOMERS_CHECKBOX_CLICKED = "Imports Bulk Change - Notify Customers Checkbox Clicked",
  IMPORTS_BULK_CHANGE_NO_OPTION_SELECTED = "Imports Bulk Change - No Option Selected",
  IMPORTS_BULK_CHANGE_PARTIAL_MIGRATION_OPTION_SELECTED = "Imports Bulk Change - Partial Migration Option Selected",
  IMPORTS_BULK_CHANGE_REMOVE_EMAIL_BUTTON_CLICKED = "Imports Bulk Change - Remove Email Button Clicked",
  IMPORTS_BULK_CHANGE_RETRY_UPLOAD_CTA_CLICKED = "Imports Bulk Change - Retry Upload CTA Clicked",
  IMPORTS_BULK_CHANGE_SEPA_OPTION_SELECTED = "Imports Bulk Change - SEPA Option Selected",
  IMPORTS_BULK_CHANGE_SHORT_SUN_NAME_TOOLTIP_CLICKED = "Imports Bulk Change - Short SUN Name Tooltip Clicked",
  IMPORTS_BULK_CHANGE_SHOW_DATA_REQUIRED_CTA_CLICKED = "Imports Bulk Change - Show Data Required CTA Clicked",
  IMPORTS_BULK_CHANGE_SHOW_VERIFICATION_STATUS_CTA_CLICKED = "Imports Bulk Change - Show Verification Status CTA Clicked",
  IMPORTS_BULK_CHANGE_SPECIAL_CHARACTERS_TOOLTIP_CLICKED = "Imports Bulk Change - Special Characters Tooltip Clicked",
  IMPORTS_BULK_CHANGE_STAGGERED_MIGRATION_CHECKBOX_CLICKED = "Imports Bulk Change - Staggered Migration Checkbox Clicked",
  IMPORTS_BULK_CHANGE_SUBMIT_FOR_REVIEW_CTA_CLICKED = "Imports Bulk Change - Submit For Review CTA Clicked",
  IMPORTS_BULK_CHANGE_SUBMIT_TICKET_CTA_CLICKED = "Imports Bulk Change - Submit Ticket CTA Clicked",
  IMPORTS_BULK_CHANGE_SUN_ISSUE_DATE_TOOLTIP_CLICKED = "Imports Bulk Change - SUN Issue Date Tooltip Clicked",
  IMPORTS_BULK_CHANGE_UPLOAD_CTA_CLICKED = "Imports Bulk Change - Upload CTA Clicked",
  IMPORTS_BULK_CHANGE_UPLOAD_FILE_CTA_CLICKED = "Imports Bulk Change - Upload File CTA Clicked",
  IMPORTS_BULK_CHANGE_VIEW_COMMON_ERRORS_CTA_CLICKED = "Imports Bulk Change - View Common Errors CTA Clicked",
  IMPORTS_BULK_CHANGE_VIEW_EXAMPLE_FORM_CTA_CLICKED = "Imports Bulk Change - View Example Form CTA Clicked",
  IMPORTS_BULK_CHANGE_WET_SIG_TOOLTIP_CLICKED = "Imports Bulk Change - Wet Sig Tooltip Clicked",
  IMPORTS_BULK_CHANGE_WHAT_IS_2FA_CTA_CLICKED = "Imports Bulk Change - What Is 2FA CTA Clicked",
  IMPORTS_BULK_CHANGE_YES_OPTION_SELECTED = "Imports Bulk Change - Yes Option Selected",
  IMPORTS_MIGRATE_ACH_MANDATES_LINK_CLICKED = "Imports - Migrate ACH Mandates Link Clicked",
  IMPORTS_MIGRATE_BACS_MANDATES_LINK_CLICKED = "Imports - Migrate Bacs Mandates Link Clicked",
  IMPORTS_MIGRATE_BECS_MANDATES_LINK_CLICKED = "Imports - Migrate BECS Mandates Link Clicked",
  IMPORTS_MIGRATE_BECS_NZ_MANDATES_LINK_CLICKED = "Imports - Migrate BECS NZ Mandates Link Clicked",
  IMPORTS_MIGRATE_SEPA_MANDATES_LINK_CLICKED = "Imports - Migrate SEPA Mandates Link Clicked",
  IMPORT_ALL_ROWS_TAB_CLICKED = "Import - All Rows Tab Clicked",
  IMPORT_APPROVE_REQUEST_CTA_CLICKED = "Import - Approve Request CTA Clicked",
  IMPORT_HELP_CORRECTING_ERRORS_CTA_CLICKED = "Import - Help Correcting Errors CTA Clicked",
  IMPORT_PROCESSED_ROWS_TAB_CLICKED = "Import - Processed Rows Tab Clicked",
  IMPORT_ROWS_WITHOUT_ERRORS_TAB_CLICKED = "Import - Rows Without Errors Tab Clicked",
  IMPORT_ROWS_WITH_ERRORS_TAB_CLICKED = "Import - Rows With Errors Tab Clicked",
  IMPORT_SUBMIT_FOR_REVIEW_CTA_CLICKED = "Import - Submit For Review CTA Clicked",
  IMPORT_UNPROCESSED_ROWS_TAB_CLICKED = "Import - Unprocessed Rows Tab Clicked",
  IMPORT_UPLOAD_CORRECTED_FILE_CTA_CLICKED = "Import - Upload Corrected File CTA Clicked",
  INTRO_BUSINESS_CATEGORY_CHANGE_CLICKED = "Intro - Business Category Change Clicked",
  INTRO_BUSINESS_CATEGORY_STEP_COMPLETED = "Intro - Business Category Step Completed",
  INTRO_BUSINESS_CATEGORY_STEP_STARTED = "Intro - Business Category Step Started",
  INTRO_BUSINESS_DETAILS_STEP_COMPLETED = "Intro - Business Details Step Completed",
  INTRO_BUSINESS_DETAILS_STEP_STARTED = "Intro - Business Details Step Started",
  INTRO_GOALS_STEP_COMPLETED = "Intro - Goals Step Completed",
  INTRO_GOALS_STEP_STARTED = "Intro - Goals Step Started",
  INTRO_HELP_CLICKED = "Intro - Help Clicked",
  INTRO_PAYMENT_VOLUMES_STEP_COMPLETED = "Intro - Payment Volumes Step Completed",
  INTRO_PAYMENT_VOLUMES_STEP_STARTED = "Intro - Payment Volumes Step Started",
  INTRO_START_STEP_COMPLETED = "Intro - Start Step Completed",
  INTRO_START_STEP_STARTED = "Intro - Start Step Started",
  LOGO_AND_BRANDING_CANCEL_BUTTON_CLICKED = "Logo and Branding - Cancel Button Clicked",
  LOGO_AND_BRANDING_SAVE_AND_FINISH_BUTTON_CLICKED = "Logo and Branding - Save And Finish Button Clicked",
  LOGO_CLICKED = "Logo Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_ACTIVATE_PAYOUTS_CLICKED = "Merchant Dashboard Homepage - Activate Payouts Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_CLOSED = "Merchant Dashboard Homepage - Create Payment Dialog Closed",
  MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_LINK_CLICKED = "Merchant Dashboard Homepage - Create Payment Dialog Link Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_OPENED = "Merchant Dashboard Homepage - Create Payment Dialog Opened",
  MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_DIALOG_CLOSED = "Merchant Dashboard Homepage - Extended Checklist Dialog Closed",
  MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_DIALOG_OPENED = "Merchant Dashboard Homepage - Extended Checklist Dialog Opened",
  MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED = "Merchant Dashboard Homepage - Extended Checklist Item Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_DISMISSED = "Merchant Dashboard Homepage - Extended Checklist Item Dismissed",
  MERCHANT_DASHBOARD_HOMEPAGE_INCENTIVISE_DISCOUNT_EXTEND_CTA_CLICKED = "Merchant Dashboard Homepage - Incentivise Discount Extend CTA Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_LAST_PAYOUT_CTA_CLICKED = "Merchant Dashboard Homepage - Last Payout CTA Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED = "Merchant Dashboard Homepage - Need Help Card Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_LINK_CLICKED = "Merchant Dashboard Homepage - Need Help Link Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_NEXT_PAYOUT_CTA_CLICKED = "Merchant Dashboard Homepage - Next Payout CTA Clicked",
  MERCHANT_DASHBOARD_HOMEPAGE_PAGE_VIEWED = "Merchant Dashboard Homepage - Page Viewed",
  MERCHANT_DASHBOARD_REPORTING_ACTIVE_CUSTOMERS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Active Customers Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_ALL_DATA_LOADED = "Merchant Dashboard Reporting - All Data Loaded",
  MERCHANT_DASHBOARD_REPORTING_CHART_TYPE_DROPDOWN_CLICKED = "Merchant Dashboard Reporting - Chart Type Dropdown Clicked",
  MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_CHART_TYPE_SELECTED = "Merchant Dashboard Reporting - Collected Payments Chart Type Selected",
  MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_CHART_WIDGET_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Collected Payments Chart Widget Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_DATA_WIDGET_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Collected Payments Data Widget Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Collected Payments Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_CUSTOM_DATE_PICKER_CLICKED = "Merchant Dashboard Reporting - Custom Date Picker Clicked",
  MERCHANT_DASHBOARD_REPORTING_ERROR_BANNER_VIEWED = "Merchant Dashboard Reporting - Error Banner Viewed",
  MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENTS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Failed Payments Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENT_ATTEMPTS_CHART_TYPE_SELECTED = "Merchant Dashboard Reporting - Failed Payment Attempts Chart Type Selected",
  MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENT_ATTEMPTS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Failed Payment Attempts Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_FEEDBACK_BUTTON_CLICKED = "Merchant Dashboard Reporting - Feedback Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_INFO_TOOLTIP_ACTIVATED = "Merchant Dashboard Reporting - info tooltip activated",
  MERCHANT_DASHBOARD_REPORTING_LAST_3_MONTHS_DATE_RANGE_SELECTED = "Merchant Dashboard Reporting - Last 3 Months Date Range Selected",
  MERCHANT_DASHBOARD_REPORTING_LAST_7_DAYS_DATE_RANGE_SELECTED = "Merchant Dashboard Reporting - Last 7 Days Date Range Selected",
  MERCHANT_DASHBOARD_REPORTING_LAST_MONTH_DATE_RANGE_SELECTED = "Merchant Dashboard Reporting - Last Month Date Range Selected",
  MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED = "Merchant Dashboard Reporting - Learn More Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_PRESET_DATE_PICKER_CLICKED = "Merchant Dashboard Reporting - Preset Date Picker Clicked",
  MERCHANT_DASHBOARD_REPORTING_TODAY_DATE_RANGE_SELECTED = "Merchant Dashboard Reporting - Today Date Range Selected",
  MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_CHART_TYPE_SELECTED = "Merchant Dashboard Reporting - Total Earnings Chart Type Selected",
  MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Total Earnings Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_TOTAL_PENDING_PAYMENTS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Total Pending Payments Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_TOTAL_PENDING_PAYOUTS_TOOLTIP_CLICKED = "Merchant Dashboard Reporting - Total Pending Payouts Tooltip Clicked",
  MERCHANT_DASHBOARD_REPORTING_VIEW_ALL_COLLECTED_PAYMENTS_BUTTON_CLICKED = "Merchant Dashboard Reporting - View All Collected Payments Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_VIEW_ALL_PAYMENTS_BUTTON_CLICKED = "Merchant Dashboard Reporting - View All Payments Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_VIEW_ALL_PENDING_PAYMENTS_BUTTON_CLICKED = "Merchant Dashboard Reporting - View All Pending Payments Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_VIEW_CUSTOMERS_BUTTON_CLICKED = "Merchant Dashboard Reporting - View Customers Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_VIEW_FAILED_PAYMENTS_BUTTON_CLICKED = "Merchant Dashboard Reporting - View Failed Payments Button Clicked",
  MERCHANT_DASHBOARD_REPORTING_VIEW_PAYOUTS_BUTTON_CLICKED = "Merchant Dashboard Reporting - View Payouts Button Clicked",
  MERCHANT_ONBOARDING_ADD_CUSTOM_SUN_CTA_CLICKED = "Merchant Onboarding -  Add custom sun CTA clicked",
  MERCHANT_ONBOARDING_BILLING_DETAILS_CALLBACK_PAGE_VIEWED = "Merchant Onboarding - Billing details callback page viewed",
  MERCHANT_ONBOARDING_BILLING_DETAILS_MANDATE_SETUP_SKIPPED = "Merchant Onboarding - Billing details: Mandate setup skipped",
  MERCHANT_ONBOARDING_BILLING_DETAILS_MANDATE_SETUP_STARTED = "Merchant Onboarding - Billing details: Mandate setup started",
  MERCHANT_ONBOARDING_BILLING_DETAILS_PAGE_VIEWED = "Merchant Onboarding - Billing details: Page viewed",
  MERCHANT_ONBOARDING_BUSINESS_OWNERS_STEP_STARTED = "Merchant Onboarding - Business Owners: Step started",
  MERCHANT_ONBOARDING_CUSTOM_ADD_ON_ADDED = "Merchant Onboarding - Custom add on added",
  MERCHANT_ONBOARDING_CUSTOM_ADD_ON_OPENED = "Merchant Onboarding - Custom add on opened",
  MERCHANT_ONBOARDING_CUSTOM_ADD_ON_REMOVED = "Merchant Onboarding - Custom add on removed",
  MERCHANT_ONBOARDING_LEARN_MORE_ABOUT_PRICING_CLICKED = "Merchant Onboarding - Learn more about Pricing Clicked",
  MERCHANT_ONBOARDING_PACKAGE_SELECTION_CTA_CLICKED = "Merchant Onboarding - Package Selection CTA clicked",
  MERCHANT_ONBOARDING_PACKAGE_SELECTION_PACKAGE_SELECTED = "Merchant Onboarding - Package Selection: Package selected",
  MERCHANT_ONBOARDING_PACKAGE_SELECTION_PAGE_VIEWED = "Merchant Onboarding - Package Selection Page Viewed",
  MERCHANT_ONBOARDING_PLANS_AND_ADDONS_SETTINGS_CTA_CLICK = "Merchant Onboarding - Plans and addons settings CTA click",
  PAYLINKS_ADD_CUSTOMER_CTA_CLICKED = "Paylinks - Add Customer CTA Clicked",
  PAYLINKS_CREATE_PAGE_VIEWED = "Paylinks - Create Page Viewed",
  PAYLINKS_CREATE_PAYLINK_CTA_CLICKED = "Paylinks - Create Paylink CTA Clicked",
  PAYLINKS_DETAIL_PAGE_VIEWED = "Paylinks - Detail Page Viewed",
  PAYLINKS_EXPANSION_PANEL_OPENED = "Paylinks - Expansion Panel Opened",
  PAYLINKS_LIST_PAGE_VIEWED = "Paylinks - List Page Viewed",
  PAYLINKS_NEW_PAYLINK_CREATED = "Paylinks - New Paylink Created",
  PAYLINKS_OPEN_LIST_CTA_CLICKED = "Paylinks - Open List CTA Clicked",
  PAYLINKS_QRCODE_CUSTOMISED = "Paylinks - QRCode Customised",
  PAYLINKS_SHARE_COMPLETED = "Paylinks - Share Completed",
  PAYLINKS_SHARE_COMPLETED_DEDUPED = "Paylinks - Share Completed Deduped",
  PAYLINKS_SHARE_PAGE_VIEWED = "Paylinks - Share Page Viewed",
  PAYLINKS_TEMPLATE_EDITED = "Paylinks - Template Edited",
  PAYLINKS_TEMPLATE_LIST_VIEWED = "Paylinks - Template List Viewed",
  PAYLINKS_TEMPLATE_PREVIEW_EXPANDED = "Paylinks - Template Preview Expanded",
  PAYLINKS_TEMPLATE_TYPE_UPDATED = "Paylinks - Template Type Updated",
  PAYMENTS_CUSTOMER_SEARCH_PAGE_VIEWED = "Payments - Customer Search Page Viewed",
  PAYMENTS_CUSTOMER_SELECTED = "Payments - Customer Selected",
  PAYMENTS_CUSTOMER_TYPE_SELECTED = "Payments - Customer Type Selected",
  PAYMENTS_DETAIL_PAGE_VIEWED = "Payments - Detail Page Viewed",
  PAYMENTS_FAILURE_PROBABILITY_SORTING_CLICKED = "Payments - Failure Probability Sorting Clicked",
  PAYMENTS_LIST_PAGE_VIEWED = "Payments - List Page Viewed",
  PAYMENTS_PLAN_PAGE_VIEWED = "Payments - Plan Page Viewed",
  PAYMENTS_SELECT_CUSTOMER_TYPE_PAGE_VIEWED = "Payments - Select Customer Type Page Viewed",
  PAYOUTS_PAGE_VIEWED = "Payouts - Page Viewed",
  PAYOUT_RECONCILIATION_EXPORT_BUTTON_CLICKED = "Payout Reconciliation - Export Button Clicked",
  PLANS_ADD_CUSTOMER_CTA_CLICKED = "Plans - Add Customer CTA Clicked",
  PLANS_ASSIGN_CUSTOMER_TAB_VIEWED = "Plans - Assign Customer Tab Viewed",
  PLANS_CANCEL_PAGE_VIEWED = "Plans - Cancel Page Viewed",
  PLANS_CREATE_PAGE_VIEWED = "Plans - Create Page Viewed",
  PLANS_CREATE_PLAN_CTA_CLICKED = "Plans - Create Plan CTA Clicked",
  PLANS_DETAIL_PAGE_VIEWED = "Plans - Detail Page Viewed",
  PLANS_EDIT_BUTTON_CLICKED = "Plans - Edit Button Clicked",
  PLANS_EDIT_PAGE_VIEWED = "Plans - Edit Page Viewed",
  PLANS_LIST_PAGE_VIEWED = "Plans - List Page Viewed",
  PLANS_NEW_PLAN_CREATED = "Plans - New Plan Created",
  PLANS_OPEN_LIST_CTA_CLICKED = "Plans - Open List CTA Clicked",
  PLANS_PAYER_BENEFITS_DOWNLOADED = "Plans - Payer Benefits Downloaded",
  PLANS_PAYMENT_SCHEDULE_CANCEL_PAUSE_CLICKED = "Plans - Payment Schedule Cancel Pause Clicked",
  PLANS_PAYMENT_SCHEDULE_FEEDBACK_BUTTON_CLICKED = "Plans - Payment Schedule Feedback Button Clicked",
  PLANS_PAYMENT_SCHEDULE_FOR_A_SET_PERIOD_CLICKED = "Plans - Payment Schedule For A Set Period Clicked",
  PLANS_PAYMENT_SCHEDULE_LINK_BUTTON_CLICKED = "Plans - Payment Schedule Link Button Clicked",
  PLANS_PAYMENT_SCHEDULE_PAUSE_CLICKED = "Plans - Payment Schedule Pause Clicked",
  PLANS_PAYMENT_SCHEDULE_RESUME_CLICKED = "Plans - Payment Schedule Resume Clicked",
  PLANS_PAYMENT_SCHEDULE_SUPPORTING_DOCS_LINK_CLICKED = "Plans - Payment Schedule Supporting Docs Link Clicked",
  PLANS_PAYMENT_SCHEDULE_TOUR_FINISH_CLICKED = "Plans - Payment Schedule Tour Finish Clicked",
  PLANS_PAYMENT_SCHEDULE_UNTIL_FURTHER_NOTICE_CLICKED = "Plans - Payment Schedule Until Further Notice Clicked",
  PLANS_PLAN_CANCELLED = "Plans - Plan Cancelled",
  PLANS_PLAN_CUSTOMER_PAGE_VIEWED = "Plans - Plan Customer Page Viewed",
  PLANS_PLAN_EDITED = "Plans - Plan Edited",
  PLANS_SHARE_COMPLETED = "Plans - Share Completed",
  PLANS_SHARE_COMPLETED_DEDUPED = "Plans - Share Completed Deduped",
  PLANS_SHARE_PAGE_VIEWED = "Plans - Share Page Viewed",
  PLANS_SHARE_TAB_UPDATED = "Plans - Share Tab Updated",
  PLANS_TEMPLATE_EDITED = "Plans - Template Edited",
  PLANS_TEMPLATE_LIST_VIEWED = "Plans - Template List Viewed",
  PLANS_TEMPLATE_PREVIEW_EXPANDED = "Plans - Template Preview Expanded",
  PLANS_TEMPLATE_TYPE_UPDATED = "Plans - Template Type Updated",
  PLAN_PAUSE_INDEFINITELY_LINK_CLICKED = "Plan - Pause Indefinitely Link Clicked",
  PLAN_PAUSE_INSTEAD_LINK_CLICKED = "Plan - Pause Instead Link Clicked",
  PLAN_QRCODE_CUSTOMISED = "Plan - QRCode Customised",
  REFERRALS_INFORMATION_CLICKED = "Referrals - Information Clicked",
  REFERRALS_REFER_BANNER_CLOSED = "Referrals - Refer Banner Closed",
  REFERRALS_REFER_PAGE_VIEWED = "Referrals - Refer Page Viewed",
  REFERRALS_SHARE_DIALOG_CLOSED = "Referrals - Share Dialog Closed",
  REFERRALS_SHARE_INITIATED = "Referrals - Share Initiated",
  REFERRALS_SHARE_LINK_CLICKED = "Referrals - Share Link Clicked",
  REQUEST_PAYMENT_CREATE_PAYMENT_CTA_CLICKED = "Request Payment - Create Payment CTA Clicked",
  REQUEST_PAYMENT_SELECT_PAGE_VIEWED = "Request Payment - Select Page Viewed",
  SEGMENT_CONSENT_PREFERENCE_UPDATED = "Segment Consent Preference Updated",
  SIGNIN_LINK_CLICKED = "Signin - Link Clicked",
  SIGNIN_PAGE_VIEWED = "Signin - Page Viewed",
  SIGNUP_90D_FEE_FREE_TERMS_LINK_CLICKED = "Signup - 90d Fee Free Terms Link Clicked",
  SIGNUP_ACCOUNT_CREATED = "Signup - Account Created",
  SIGNUP_ACCOUNT_LOCKED = "Signup - Account Locked",
  SIGNUP_BACK_BUTTON_CLICKED = "Signup - Back Button Clicked",
  SIGNUP_BUSINESS_NAME_TOOLTIP_CLICKED = "Signup - Business Name Tooltip Clicked",
  SIGNUP_BUSINESS_TYPE_FIELD_UPDATED = "Signup - Business Type Field Updated",
  SIGNUP_COMPANY_INFO_PAGE_VIEWED = "Signup - Company Info Page Viewed",
  SIGNUP_EMAIL_FIELD_VALIDATION_FAILED = "Signup - Email Field Validation Failed",
  SIGNUP_EMAIL_FIELD_VERIFICATION_FAILED = "Signup - Email Field Verification Failed",
  SIGNUP_EMAIL_PAGE_VIEWED = "Signup - Email Page Viewed",
  SIGNUP_EMAIL_VERIFICATION_PAGE_VIEWED = "Signup - Email Verification Page Viewed",
  SIGNUP_EMAIL_VERIFIED = "Signup - Email Verified",
  SIGNUP_FORM_FIELD_INTERACTION = "Signup - Form Field Interaction",
  SIGNUP_LOGIN_CLICKED = "Signup - Login Clicked",
  SIGNUP_PAGE_VIEWED = "Signup - Page Viewed",
  SIGNUP_PASSWORD_FIELD_VALIDATION_FAILED = "Signup - Password Field Validation Failed",
  SIGNUP_PASSWORD_VALIDATION_FAILED = "Signup - Password Validation Failed",
  SIGNUP_PRIVACY_POLICY_CLICKED = "Signup - Privacy Policy Clicked",
  SIGNUP_REFERRAL_SCHEME_TERMS_LINK_CLICKED = "Signup - Referral Scheme Terms Link Clicked",
  SIGNUP_REGULATORY_INFO_LINK_CLICKED = "Signup - Regulatory Info Link Clicked",
  SIGNUP_RESEND_CODE_CLICKED = "Signup - Resend Code Clicked",
  SIGNUP_SIGNUP_FAILED = "Signup - Signup Failed",
  SIGNUP_TERMS_LINK_CLICKED = "Signup - Terms Link Clicked",
  SUBSCRIPTIONS_CANCEL_PAGE_VIEWED = "Subscriptions - Cancel Page Viewed",
  SUBSCRIPTIONS_DETAIL_PAGE_CTA_CLICKED = "Subscriptions - Detail Page CTA Clicked",
  SUBSCRIPTIONS_DETAIL_PAGE_VIEWED = "Subscriptions - Detail Page Viewed",
  SUBSCRIPTIONS_LIST_PAGE_VIEWED = "Subscriptions - List Page Viewed",
  SUBSCRIPTIONS_SUBSCRIPTION_CANCELLED = "Subscriptions - Subscription Cancelled",
  SUBSCRIPTIONS_SUBSCRIPTION_PAUSE_CTA_CLICKED = "Subscriptions - Subscription Pause CTA clicked",
  SUBSCRIPTIONS_SUBSCRIPTION_RESUMED = "Subscriptions - Subscription Resumed",
  SUBSCRIPTIONS_SUBSCRIPTION_RESUME_CTA_CLICKED = "Subscriptions - Subscription Resume CTA clicked",
  SUBSCRIPTION_SUBSCRIPTION_PAUSED = "Subscription - Subscription Paused",
  SUPPORT_ZENDESK_LINK_CLICKED = "Support - Zendesk link clicked",
  SUPPORT_ZENDESK_LINK_INTERCEPTED = "Support - Zendesk link intercepted",
  VERIFICATION_ACH_UPLIFT_DETAILS_PAGE_VIEWED = "Verification - Ach Uplift Details Page Viewed",
  VERIFICATION_ACH_UPLIFT_DETAILS_SUBMITTED = "Verification - Ach Uplift Details Submitted",
  VERIFICATION_ADDITIONAL_DETAILS_EXPANDED = "Verification - Additional Details Expanded",
  VERIFICATION_ADDITIONAL_DETAILS_ID_CHECK_COMPLETED = "Verification - Additional Details ID Check Completed",
  VERIFICATION_ADDITIONAL_DETAILS_ID_CHECK_STARTED = "Verification - Additional Details ID Check Started",
  VERIFICATION_ADDITIONAL_DETAILS_ONFIDO_ID_CHECK_STARTED = "Verification - Additional Details Onfido ID Check Started",
  VERIFICATION_ADDITIONAL_DETAILS_ONFIDO_ID_CHECK_SUBMITTED = "Verification - Additional Details Onfido ID Check Submitted",
  VERIFICATION_ADDITIONAL_DETAILS_PAGE_VIEWED = "Verification - Additional Details Page Viewed",
  VERIFICATION_ADDITIONAL_DETAILS_UPLOAD_DOCUMENT_COMPLETED = "Verification - Additional Details Upload Document Completed",
  VERIFICATION_ADDITIONAL_DETAILS_UPLOAD_DOCUMENT_STARTED = "Verification - Additional Details Upload Document Started",
  VERIFICATION_ADD_ADDITIONAL_INFORMATION_CLICKED = "Verification - Add Additional Information Clicked",
  VERIFICATION_BANK_ACCOUNTS_MANUALLY_ADD_PAGE_VIEWED = "Verification - Bank Accounts Manually Add Page Viewed",
  VERIFICATION_BANK_STATEMENT_NAME_PAGE_VIEWED = "Verification - Bank Statement Name Page Viewed",
  VERIFICATION_BANK_STATEMENT_NAME_SUBMITTED = "Verification - Bank Statement Name Submitted",
  VERIFICATION_BANK_STATEMENT_NAME_UPGRADE_FULFILLED = "Verification - Bank Statement Name Upgrade Fulfilled",
  VERIFICATION_BILLING_DETAILS_MANDATE_SETUP_FULFILLED = "Verification - Billing details: Mandate setup fulfilled",
  VERIFICATION_BUSINESS_CONTACT_PAGE_TOOLTIP_CLICKED = "Verification - Business Contact Page Tooltip Clicked",
  VERIFICATION_MORE_BUSINESS_DETAILS_PAGE_VIEWED = "Verification - More Business Details Page Viewed",
  VERIFICATION_MORE_BUSINESS_DETAILS_SUBMITTED = "Verification - More Business Details Submitted",
  VERIFICATION_PAOB_PAGE_VIEWED = "Verification - PAOB Page Viewed",
  VERIFICATION_PAOB_SUBMITTED = "Verification - PAOB Submitted",
  VERIFICATION_PAYMENT_VOLUMES_PAGE_VIEWED = "Verification - Payment Volumes Page Viewed",
  VERIFICATION_PAYMENT_VOLUMES_SUBMITTED = "Verification - Payment Volumes Submitted",
  VERIFICATION_STATUS_PAGE_HAVING_TROUBLE_CLICKED = "Verification - Status Page: Having Trouble Clicked",
  VERIFICATION_VERIFY_BANK_DOCUMENT_PAGE_VIEWED = "Verification - Verify Bank Document Page Viewed",
  VERIFICATION_VERIFY_BANK_DOCUMENT_SUBMITTED = "Verification - Verify Bank Document Submitted",
}
